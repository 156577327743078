//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-trip-complaint-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,
      complaint:'',

    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
        doCreateComplaint: 'complaint/doCreateComplaint'
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    onSubmit(){
        console.log(this.complaint,this.trip.id);
        this.doCreateComplaint({complaint: this.complaint,trip: this.trip.id})
        this.$emit('close');
    }
  },
};
