//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { TripPermissions } from '@/modules/trip/trip-permissions';
import TripListCard from '@/modules/trip/components/trip-list-card.vue';
import TripListTable from '@/modules/trip/components/trip-list-table.vue';

// import { i18n } from '@/i18n';

export default {
  name: 'app-trip-list-page',

  components: {
    [TripListCard.name]: TripListCard,
    [TripListTable.name]: TripListTable
  },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      rows: 'trip/list/rows'
    }),
    hasPermissionToCreate() {
      return new TripPermissions(this.currentUser).create;
    },
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    goToCreatePage(){
      this.$router.push( 
        {
          name: 'tripNew'
        })
    },
   
  },
  
};
