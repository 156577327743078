import { render, staticRenderFns } from "./trip-card.vue?vue&type=template&id=edc00c80&scoped=true&"
import script from "./trip-card.vue?vue&type=script&lang=js&"
export * from "./trip-card.vue?vue&type=script&lang=js&"
import style0 from "./trip-card.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./trip-card.vue?vue&type=style&index=1&id=edc00c80&lang=scss&scoped=true&"
import style2 from "./trip-card.vue?vue&type=style&index=2&id=edc00c80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edc00c80",
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QSeparator,QCardActions,QBtn,QToggle} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QSeparator,QCardActions,QBtn,QToggle})
