//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import  i18n  from '@/vueI18n';
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import Roles from '@/security/roles';
import moment from 'moment';
const { fields } = UserModel;

export default {
  name: 'app-trip-list-table',
    components: {
  },
    props: {
    
  },

  data() {
    return {
      record: null,
      createAdminDialogVisible:false ,
      createAdminModalVisible: false,
      viewAdminDialogVisible:false ,
      viewAdminModalVisible: false,
      deleteAdminDialogVisible:false ,
      deleteAdminModalVisible: false,
      modalSuccessVisible: false,


      selectedRow: null,
      selectedId: null,
      dialogType: null,
      modalVisible: false,
      dialogVisible: false,
      pagination: {
        page: 1,
        rowsPerPage: 10 
      },
      page: 1,
      columns: [
        {
          name: 'planName',
          align: 'center',
          label: this.i18n('tables.trip.planName'),
          field: 'planName',
        },              
         {
          name: 'reservationCount',
          align: 'center',
          label: this.i18n('tables.trip.reservationCount'),
          field: 'reservationCount',
        },{
          name: 'tripTime',
          align: 'center',
          label: this.i18n('tables.trip.tripTime'),
          field: 'tripTime',
        }, 
        {
          name: 'action',
          align: 'center',
          label: this.i18n('tables.trip.action'),
          field: 'action',
        },
      ],
      tripTab: "scheduled",
      status: "scheduled"
    };
  },

   watch:{
    async tripTab(newval){
        this.status = newval
        this.page = 1
        await this.doFetch({
          ownerId: this.currentUser.companyId,
          filter:{
            status: newval
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: this.getItemsPerPage
          }
        })
      
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      rows: 'trip/list/rows',
      disablePrevious: 'admins/list/disablePrevious',
      disableNext: 'admins/list/disableNext',
      is_screen_xs: 'layout/is_screen_xs',
      loading: 'trip/list/loading',
      isFirstPage: 'trip/list/isFirstPage',
      isLastPage: 'trip/list/isLastPage'
    }),

    fields() {
      return fields;
    },
    getItemsPerPage() {
      // const { screen } = this.$q;
      //   if (screen.lt.sm) {
      //       return 4;
      //   }
      //   if (screen.lt.md) {
      //       return 6;
      //   }
      //   if (screen.lt.lg) {
      //       return 9;
      //   }
      //   if (screen.lt.xl) {
      //       return 9;
      //   }
        return 10;
    },
    isRTL(){
      return i18n.locale == 'ar'
    },
    currentLanguageCode(){
        return i18n.locale
    }
  },
  async mounted() {
    await this.doFetch({
      ownerId: this.currentUser.companyId,
      filter:{
        status: 'scheduled'
      },
      orderBy: "createdAt",
      pagination: {
        sortBy: "desc",
        limit: this.getItemsPerPage
      }
    })
  },

  methods: {
    ...mapActions({
      doFetch: 'trip/list/doFetch',
    }),

    presenterDate(date) {
      return moment(date).locale(this.currentLanguageCode).format("D MMMM"); 
    },
    presenterTime(date) {
      return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
    },

    goToCreatePage(){
      this.$router.push({
          name: 'tripNew'
        })
    },
    goToViewPage(id){
        // console.log('view');
      this.$router.push(`/en/trip/${id}`)

    },
    async goToPreviousPage(){
        console.log('go to previous page');
        console.log('prev');
        const orderBy = 'createdAt'
        const pagination = {
            sortBy: "desc",
            limit: this.getItemsPerPage,
            action: "prev",
            page: this.page
        }
        await this.doFetch({
            ownerId: this.currentUser.companyId,
            filter:{
                status: this.status
            },
            orderBy,
            pagination
        }) 
      this.page -= 1
    },
    async loadMore(){
        const orderBy = 'createdAt'
        const pagination = {
            sortBy: "desc",
            limit: this.getItemsPerPage,
            action: "next",
            page: this.page
        }
        await this.doFetch({
          ownerId: this.currentUser.companyId,
          filter:{
            status: this.status
          },
          orderBy,
          pagination
        })
        this.page += 1
    },
    displayName(value){
      return value ? this.isRTL ? value.name.ar : value.name.en : '___'
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    // presenter function display data from model to fields 
    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      console.log(this.selectedId);
      switch (operation) {
          case 'delete':
          this.dialogType = 'delete'
          break;
          case 'edit':
          this.dialogType = 'edit'
          break;
          case 'view':
          this.dialogType = 'view'
          break;  
          default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
          this.modalVisible = false;
          this.dialogType = undefined
      }, 500);
    },

    onOpenCreateAdminModal() {
      this.createAdminModalVisible = true;
      this.createAdminDialogVisible = true;
    },
    onCreateAdminModalClose() {
      this.createAdminDialogVisible = false;
      setTimeout(() => {
        this.createAdminModalVisible = false;
      }, 200);
    },
    onOpenViewAdminModal() {
      this.viewAdminModalVisible = true;
      this.viewAdminDialogVisible = true;
    },
    onViewAdminModalClose() {
      this.viewAdminDialogVisible = false;
      setTimeout(() => {
        this.viewAdminModalVisible = false;
      }, 200);
    },
    onModalSuccessClose() {
      console.log('CLOSED');
      this.success = false;
      setTimeout(() => {
        this.modalSuccessVisible = false;
      }, 200);
    },
    viewAdmin(row) {
      this.selectedRow=row
      this.onOpenViewAdminModal()
    },
    adminSaved() {
      this.onCreateAdminModalClose();
      this.success = true;
      this.modalSuccessVisible = true;
    },
    deleteAdmin(row){
      this.selectedRow=row
      this.onOpenDeleteAdminModal()
    },
    onOpenDeleteAdminModal() {
      this.deleteAdminModalVisible = true;
      this.deleteAdminDialogVisible = true;
    },
    onDeleteAdminModalClose() {
      this.deleteAdminDialogVisible = false;
      setTimeout(() => {
        this.deleteAdminModalVisible = false;
      }, 200);
    },
    async doDestroyWithConfirm() {      
            try {
                console.log(this.selectedId);
                await this.doDisableAllSelected([this.selectedId])     
                this.onModalClose()  
            } catch (error) {
                // no
            }
        },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
