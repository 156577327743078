//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { TripPermissions } from '@/modules/trip/trip-permissions';
import TripCard from '@/modules/trip/components/trip-card.vue';
// import TripFormModal from '@/modules/trip/components/trip-form-modal.vue';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'

export default {
  name: 'app-trip-list-card',
  props: {
  },

  components: {
    [TripCard.name]: TripCard,
    // [TripFormModal.name]: TripFormModal,
  },

  data() {
    return {
      current: 3,
      modalVisible: false,
      dialogVisible: false,
      page: 1,
      activeTripTab: "scheduled"
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      rows: 'trip/list/rows',
      count: 'trip/list/count',
      loading: 'trip/list/loading',
      disableNext: 'trip/list/disableNext',
      disablePrevious: 'trip/list/disablePrevious'


      // rows: 'iam/list/rows',
      // count: 'iam/list/count',
      // loading: 'iam/list/loading',
      // pagination: 'iam/list/pagination',
    }),
    hasPermissionToCreate() {
      return new TripPermissions(this.currentUser).create;
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
  },
   watch:{
    async activeTripTab(newval){
      this.page = 1
      if(newval == 'scheduled'){
        await this.doFetch({
          ownerId: this.currentUser.companyId,
          filter:{
            status: 'scheduled'
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: 3
          }
        })
        console.log('this.rows ===> ', this.rows);
        await this.setDisablePrevious({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'})
        await this.setDisableNext({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'}) 
      }
      if(newval == 'started'){
        // const pagination = {
        //   sortBy: "desc",
        //   limit: this.getItemsPerPage
        // }
        // this.status = 'pending'
        // await this.doFetch({status: this.status, orderBy: 'createdAt', pagination})
        // await this.setDisablePrevious({status: this.status})
        // await this.setDisableNext({status: this.status})    
        // this.finishLoading()
        await this.doFetch({
          ownerId: this.currentUser.companyId,
          filter:{
            status: 'started'
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: 3
          }
        })
        console.log('this.rows ===> ', this.rows);
        await this.setDisablePrevious({companyId: this.currentUser.companyId, filter: { status: "started" }, orderBy: 'createdAt'})
        await this.setDisableNext({companyId: this.currentUser.companyId, filter: { status: "started" }, orderBy: 'createdAt'}) 
      }
      
    }
  },

  methods: {
    ...mapActions({
        doFetch: 'trip/list/doFetch',
        setDisableNext: 'trip/list/setDisableNext',
        setDisablePrevious: 'trip/list/setDisablePrevious',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    goToCreatePage(){
      this.$router.push({
        name: 'tripNew'
      })
    },
    async goToPreviousPage(){
      console.log('previous');
      await this.doFetch(
        {
          ownerId: this.currentUser.companyId,
          filter:{
            status: 'scheduled'
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: 3,
            page: this.page,
            action: "prev"
          }
        }
      )
      this.page = this.page - 1
      this.$router.push({        
        query: {
          page: this.page,
        },
      })

      await this.setDisablePrevious({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'})
      await this.setDisableNext({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'}) 
    },
    async loadMore(){
      console.log('load more');
      await this.doFetch(
        {
          ownerId: this.currentUser.companyId,
          filter:{
            status: 'scheduled'
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: 3,
            page: this.page,
            action: "next"
          }
        }
      )
      this.page = this.page + 1
      this.$router.push({        
        query: {
          page: this.page,
        },
      })

      await this.setDisablePrevious({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'})
      await this.setDisableNext({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'}) 
    }
  },
   async mounted(){
    await this.doFetch({
      ownerId: this.currentUser.companyId,
      filter:{
        status: 'scheduled'
      },
      orderBy: "createdAt",
      pagination: {
        sortBy: "desc",
        limit: 3
      }
    })    
    await this.setDisablePrevious({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'})
    await this.setDisableNext({companyId: this.currentUser.companyId, filter: { status: "scheduled" }, orderBy: 'createdAt'}) 
  }
};
