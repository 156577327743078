//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/vueI18n';
import moment from 'moment';


export default {
  name: 'app-trip-view-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,

      trip: {
          id: '1',
          capacity: 25,
          disabled: true,
          tripTime: '2021-05-08T20:59:08.103Z',
          name: {
            en: 'Mekkah trip',
            ar: 'Mekkah trip',
          },
          plan: {
            name: {
              en: 'Plan A',
              ar: 'خطة أ',
            }
          },
          city: {
            name: {
              en: 'El Madina, Saudi Arabia',
              ar: 'El Madina, Saudi Arabia',
            }
          },
          guides: [
            {
              fullName: 'Ahmed Omar',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
            },
            {
              fullName: 'Nader Mostafa',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
            },
          ],

          clients: [
            {
              fullName: 'Ahmed Omar',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'
            },
            {
              fullName: 'Nader Mostafa',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'

            },
            {
              fullName: 'Ahmed Omar',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'
            },
            {
              fullName: 'Nader Mostafa',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'

            },
            {
              fullName: 'Ahmed Omar',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'
            },
            {
              fullName: 'Nader Mostafa',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'

            },
            {
              fullName: 'Ahmed Omar',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'
            },
            {
              fullName: 'Nader Mostafa',
              avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
              phoneNumber: '+9666353725'

            },
          ],

          createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
          createdAt: '2021-02-08T20:59:08.103Z',
          updatedAt: '2021-02-08T20:59:08.103Z',
        },


    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      record: 'trip/view/record'
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
  },

  methods: {
    ...mapActions({
      doFind: 'trip/view/doFind'
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },


    presenterDate(date) {
      return moment(date).locale(this.currentLanguageCode).format("D MMMM"); 
    },
    presenterDay(date) {
      return moment(date).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(date) {
      return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
    },
    presenterName(value){
      return this.isRTL ? value.ar : value.en
    }
  },

  async created(){
    await this.doFind({id: this.id, includeCount: true})
  }
};
